$black: #000;
$white: #fff;
$grey-text: #999;
$light-background: #f4fbff;
$dark-background: #333333;

$theme-background: #e5e5e5;
$theme-primary: #082d72;
$theme-primary-two: #5cb9e2;
$theme-secondary: #eeba00;
$theme-bgc: #f8fffe;
$theme-bgc-primary: #ffedf4;
$theme-bgc-secondary: #cef9e8;

// mixins
@mixin secondary-btn($size, $color, $bgColor, $fontFamily) {
  font-size: $size;
  color: $color;
  font-family: $fontFamily;
  padding: 15px 20px;
  background-color: $bgColor;
  border-radius: 8px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid $bgColor;
  cursor: pointer;
}

@mixin text-size($size, $font, $color, $style, $weight) {
  font-family: $font;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin horizontal-line($width, $type, $color, $m-top, $m-bottom) {
  border: $width $type $color;
  margin-top: $m-top;
  margin-bottom: $m-bottom;
}

@mixin primary-btn($bg, $font-color, $font-size, $font) {
  color: $font-color;
  background: $bg;
  font-size: $font-size;
  font-family: $font;
  border: 1px solid $bg;
  border-radius: 8px;
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

//fonts
