@import "./variable.scss";

@import "~@angular/material/theming";

@include mat-core();

$gltWebsite-primary: mat-palette($mat-indigo);
$gltWebsite-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gltWebsite-warn: mat-palette($mat-red);

$gltWebsite-theme: mat-light-theme(
  (
    color: (
      primary: $gltWebsite-primary,
      accent: $gltWebsite-accent,
      warn: $gltWebsite-warn,
    ),
  )
);

@include angular-material-theme($gltWebsite-theme);

.cursor-pointer {
  cursor: pointer;
}
body {
  display: block;
  width: 1366px;
  margin: auto;
  background-color: $black;
}
// .common-width {
//   width: 1366px !important;
//   margin: auto;
// }
@media only screen and (max-width: 1366px) {
  html {
    width: 100vw;
    overflow-x: hidden;
  }
  body {
    display: block;
    width: 100%;
    overflow-x: hidden;
    margin: auto;
    background-color: $black;
  }
  // .common-width {
  //   width: 100% !important;
  // }
}

@font-face {
  font-family: rubikReg;
  src: url("./../../assets/fonts/rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: rubikBlack;
  src: local("Rubik-Black"),
    url("./../../assets/fonts/rubik/Rubik-Black.ttf") format("truetype");
}

@font-face {
  font-family: rubikReg;
  src: local("Rubik-Light"),
    url("./../../assets/fonts/rubik/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: rubikBold;
  src: local("Rubik-Bold"),
    url("./../../assets/fonts/rubik/Rubik-Bold.ttf") format("truetype");
}
